<template>
    <vs-table
        ref="table"
        v-model="selected"
        search
        :multiple="!singleSelect"
        :data="invoices"
        :sst="true"
        @search="search"
        @selected="$emit('selectedCommission', $event.id)"
    >
        <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-end"
        >
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>
            <!-- Actions -->

            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> Action </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('createCommission')">
                        <span>Create</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>

            <!-- ITEMS PER PAGE -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2">{{ itemsPerPage }}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
                        <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
                        <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
                        <span>50</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <template slot="thead">
            <vs-th>User</vs-th>
            <vs-th>Broker</vs-th>
            <vs-th>Date Added</vs-th>
            <vs-th>Amount</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td>
                        <span v-if="data[index].user">{{
                            data[index].user.details.full_name
                        }}</span>
                        <span v-else-if="data[index].name">{{
                            data[index].name
                        }}</span>
                    </vs-td>

                    <vs-td
                        ><span v-if="data[index].broker">{{
                            data[index].broker.company_name
                        }}</span></vs-td
                    >

                    <vs-td>{{ formatDate(data[index].date_added) }}</vs-td>

                    <vs-td
                        ><span v-if="data[index].total"
                            >£{{ data[index].total }}</span
                        ></vs-td
                    >
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'CommissionsAllTable',
    mixins: [textFormat, tableHelpers],
    props: ['data', 'itemsPerPage', 'dataSelected', 'limit'],
    computed: {
        invoices() {
            if (this.data) return this.data.data
            return null
        },
        selected: {
            set(value) {
                this.$emit('selected', value)
            },
            get() {
                return this.dataSelected
            },
        },
    },
    data() {
        return {
            searchQuery: '',
            status: 'All',
            singleSelect: true,
        }
    },
    methods: {
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        setLimit(status, value) {
            this.status = status
            this.$emit('limit', value)
        },
        to(data) {
            if (data.broker) return data.broker.company_name
            if (data.charity) return data.charity.charity_name
            return ''
        },
    },
}
</script>
